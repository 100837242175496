import MobileDetect from 'mobile-detect';
import moment from 'moment';
import { GetServerSideProps, InferGetServerSidePropsType, Metadata } from 'next';
import Head from 'next/head';
import * as React from 'react';

import { UserSession } from '@/contexts/auth/session';
import { RootLayout } from '@/layouts';
import { getPagesIsImpersonatingSS } from '@/lib/sessionv2/getPagesIsImpersonatingSS';
import { Homepage } from '@/modules/tourney/homepage/Homepage';
import { NextPageWithLayout } from '@/pages/_app';
import { getServerSession } from '@/utils/getServerSession';
import { IpApi } from '@/utils/types';

interface HomePageProps {
	isMobile: boolean;
	session: UserSession;
	metadata: any;
	ipAddress?: string;
	ipApi?: IpApi | null;
	redirectToNewDetailsPage?: boolean;
	isImpersonationInProcess?: boolean;
}

export const getServerSideProps: GetServerSideProps<HomePageProps> = (async ({ req, res }) => {
	let isMobile = false;
	let ipAddress = '';

	const redirectToNewDetailsPage: boolean = process.env.APP_ENV === 'dev';

	if (req.headers['user-agent']) {
		isMobile = new MobileDetect(req.headers['user-agent']).mobile() !== null;
	}

	if (req.headers['x-forwarded-for']) {
		ipAddress = req.headers['x-forwarded-for']?.toString();
	}

	if (process.env.NODE_ENV === 'development') {
		ipAddress = '162.223.93.194';
	}

	let ipApi: IpApi | null = null;

	if (ipAddress) {
		const headers = new Headers();
		headers.append('PB-API-TOKEN', process.env.API_KEY as string);

		const response = await fetch(`http://pro.ip-api.com/json/${ipAddress}?key=${process.env.IP_API_KEY}`, {
			method: 'GET',
			cache: 'no-cache',
			headers
		});

		ipApi = await response.json();
	}

	const session = await getServerSession(req, res);
	const metadata = generateMetadata();
	const isImpersonationInProcess = getPagesIsImpersonatingSS(req);

	return {
		props: {
			isMobile,
			session,
			isTourneyPage: true,
			metadata,
			ipAddress,
			ipApi,
			redirectToNewDetailsPage,
			isImpersonationInProcess
		}
	};
}) satisfies GetServerSideProps;

const generateMetadata = (): Metadata => {
	return {
		title: 'Find nearby Pickleball Tournaments',
		description: 'Explore Pickleball tournaments happening all over the world. Browse charity and competitive pickleball tournaments',
		keywords:
			'Pickleball News, Pro Pickleball, Tournament Site, Bracket Generator, Pickleball Tournaments, Pickleball Clinics, Pickleball League, Pickleball Brackets, Elimination Bracket, Round Robin, Pool Play, Blind Draw, Singles, Doubles, Mix Doubles, Mens, Womens, Teams, Pickleball, Pickleball Paddle, Paddle, Pickleball Registrations, USAP, Pickleball Canada',
		openGraph: {
			siteName: 'PickleballTournaments.com',
			url: `${process.env.NEXT_PUBLIC_PT_URL}`,
			title: 'Find nearby Pickleball Tournaments',
			description: 'Explore Pickleball tournaments happening all over the world. Browse charity and competitive pickleball tournaments',
			images: [
				{
					url: `https://pickleball.com/pickleball.png`
				}
			],
			type: 'website'
		},
		twitter: {
			card: 'summary_large_image',
			site: 'pickleball',
			title: 'Find nearby Pickleball Tournaments',
			description: 'Explore Pickleball tournaments happening all over the world. Browse charity and competitive pickleball tournaments',
			images: [
				{
					url: `https://pickleball.com/pickleball.png`
				}
			]
		},
		alternates: {
			canonical: `${process.env.NEXT_PUBLIC_PT_URL}`
		}
	};
};

export const HomePage: NextPageWithLayout<InferGetServerSidePropsType<typeof getServerSideProps>> = ({
	metadata,
	ipAddress,
	ipApi,
	redirectToNewDetailsPage
}) => {
	return (
		<main>
			<Head>
				<title>{metadata.title}</title>
				<meta key="description" name="description" content={metadata.description} />
				<meta property="keywords" content={metadata.keywords} />
				<meta property="og:type" content={metadata.openGraph.type} />
				<meta property="og:url" content={metadata.openGraph.url} />
				<link rel="canonical" href={metadata.alternates.canonical} />
				<meta property="og:site_name" content={metadata.openGraph.siteName} />
				<meta property="og:title" content={metadata.openGraph.title} />
				<meta property="og:image" content={metadata.openGraph.images[0].url} />
				<meta property="og:description" content={metadata.openGraph.description} />
				<meta name="twitter:card" content={metadata.twitter.card} />
				<meta name="twitter:site" content={metadata.twitter.site} />
				<meta name="twitter:title" content={metadata.twitter.title} />
				<meta name="twitter:description" content={metadata.twitter.description} />
				<meta name="twitter:image" content={metadata.twitter.images[0].url} />
				<meta name="COPYRIGHT" content={`(c) 2015-${moment().get('year')} Pickleball OpCo LLC`} />
			</Head>
			<Homepage redirectToNewDetailsPage={redirectToNewDetailsPage} ipApi={ipApi} ipAddress={ipAddress} />
		</main>
	);
};

HomePage.getLayout = function (page) {
	return <RootLayout>{page}</RootLayout>;
};

export default HomePage;
