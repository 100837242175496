import { Url } from 'next/dist/shared/lib/router/router';
const SSO_BASE = process.env.NEXT_PUBLIC_SSO_ENDPOINT;

const isSsoURL = (url: string | URL | Url) => {
	if (!SSO_BASE) return false;

	if (typeof url === 'string') {
		return url.includes(SSO_BASE);
	}

	return url ? (url.href ? url.href.includes(SSO_BASE) : false) : false;
};

export default isSsoURL;
