import { TourneyGoverningBodies } from 'protos/model';

export interface TourneyDetails {
	AcceptedPayment_Custom_Active: boolean;
	AcceptedPayment_Custom_Content: string;
	AcceptedPayment_Custom_Title: string;
	AcceptedPayment_MailIn_Active: boolean;
	AcceptedPayment_MailIn_Address1: string;
	AcceptedPayment_MailIn_Address2: string;
	AcceptedPayment_MailIn_AttName: string;
	AcceptedPayment_MailIn_City: string;
	AcceptedPayment_MailIn_CountryAbbreviation: string;
	AcceptedPayment_MailIn_CountryID: number;
	AcceptedPayment_MailIn_CountryTitle: string;
	AcceptedPayment_MailIn_DateTime: any;
	AcceptedPayment_MailIn_FullName: string;
	AcceptedPayment_MailIn_PayableTo: string;
	AcceptedPayment_MailIn_PrintCCInput: boolean;
	AcceptedPayment_MailIn_RequiredMemo: string;
	AcceptedPayment_MailIn_StateAbbreviation: string;
	AcceptedPayment_MailIn_StateID: number;
	AcceptedPayment_MailIn_StateTitle: string;
	AcceptedPayment_MailIn_Zip: string;
	AcceptedPayment_Onsite_Active: boolean;
	AcceptedPayment_Onsite_DateTime: any;
	AcceptedPayment_Onsite_FullName: string;
	AcceptedPayment_PayPal_Active: boolean;
	AcceptedPayment_PayPal_CorrelationID: string;
	AcceptedPayment_PayPal_DateTime: any;
	AcceptedPayment_PayPal_EmailOrMerchantID: string;
	AcceptedPayment_PayPal_EmailOrMerchantID2: string;
	AcceptedPayment_PayPal_FullName: string;
	AcceptedPayment_PayPal_ProActive: boolean;
	AcceptedPayment_PayPal_RedirectIPN: string;
	AcceptedPayment_PayPal_Token: string;
	AcceptedPayment_PayPal_TokenSecret: string;
	AcceptedPayment_Redirect_Active: boolean;
	AcceptedPayment_Redirect_Title: string;
	AcceptedPayment_Redirect_URL: string;
	AcceptedPayment_Stripe_APIKey_Publishable: string;
	AcceptedPayment_Stripe_APIKey_Secret: string;
	AcceptedPayment_Stripe_Active: boolean;
	AcceptedPayment_Stripe_DateTime: any;
	ActivateRefereeSignup: boolean;
	ActivateVolunteerSignup: boolean;
	AdditionFee_Cost_1: string;
	AdditionFee_Cost_2: string;
	AdditionFee_Cost_3: string;
	AdditionFee_Cost_4: string;
	AdditionFee_Cost_5: string;
	AdditionFee_Text_1: string;
	AdditionFee_Text_2: string;
	AdditionFee_Text_3: string;
	AdditionFee_Text_4: string;
	AdditionFee_Text_5: string;
	AdditionalInformation: string;
	AdvertiseOnly: boolean;
	AllowAlcohol: boolean;
	AllowBringDishToPass: boolean;
	AllowFood: boolean;
	AllowGrill: boolean;
	AllowPets: boolean;
	AllowRefereeSignup: boolean;
	AllowVolunteerSignup: boolean;
	BillingEmails: string;
	BlockAttendeesFromWithdrawing: boolean;
	CC_Email_FromWaitingList: string;
	CC_Email_NewRegistration: string;
	CC_Email_ToWaitingList: string;
	CC_Email_UpdatedRegistration: string;
	CC_Email_Withdrawn: string;
	CanAttendeeWithdrawFromEntireEventAtOnce: number;
	CanManage: number;
	CanRegisterMultipleOfSamePlayerGroupFormat: number;
	CancelTourney: boolean;
	Caption: string;
	ClickCountFromLinkTracking: number;
	ClubID: string;
	ClubTitle: string;
	ClubTypeTitle: string;
	ContactForm_MyWebsiteAccount: string;
	ContactForm_Other: string;
	ContactForm_PublicDisplay_Email: string;
	ContactForm_PublicDisplay_Name: string;
	ContactForm_PublicDisplay_Phone: string;
	ContactForm_Referee: string;
	ContactForm_Refunds: string;
	ContactForm_Registration: string;
	ContactForm_RequestPartnerChange: string;
	ContactForm_Sponsor: string;
	ContactForm_Vendor: string;
	ContactForm_Volunteer: string;
	ContactPerson1_UserID: string;
	ContactPerson2_Email: string;
	ContactPerson2_Name: string;
	ContactPerson2_Phone: string;
	ContactPerson2_Phone_AreaCode: string;
	ContactPerson2_Phone_CallingCode: string;
	ContactPerson2_Phone_CallingCodeCountryID: number;
	ContactPerson2_UserID: string;
	ContactPerson_Email: string;
	ContactPerson_Name: string;
	ContactPerson_Phone: string;
	ContactPerson_Phone_AreaCode: string;
	ContactPerson_Phone_CallingCode: string;
	ContactPerson_Phone_CallingCodeCountryID: number;
	Cost_ChargeSalesTax: boolean;
	Cost_FeeOnEvents: string;
	Cost_GiveRefundOnWithdrawal: boolean;
	Cost_NumberActivitiesIncludedInRegCost: number;
	Cost_Registration_ClubMember: string;
	Cost_Registration_Current: string;
	Cost_Registration_Current_OutOfState: string;
	Cost_SpectatorFee: string;
	Cost_WithdrawalFee: string;
	CountGamesTowardClubRatings: boolean;
	CountryID_PlayedIn: number;
	CountryTitle_PlayedIn: string;
	Country_System_TextNumber: string;
	CourtDesk_MinutesToEstimatedTimeForNextUp: number;
	CourtRunType: string;
	CreatedByFullName: string;
	DateAgeLimitDeadline: string;
	DateCreated: string;
	DateDeleted: any;
	DateLocked: any;
	DatePreRegistrationAccess: string;
	DatePreRegistrationAccess_UTC: string;
	DateShirtDeadline: any;
	DateShirtDeadlineHasAlreadyPassed: number;
	DateToShowTourneyToPublic: string;
	DateToShowTourneyToPublic_UTC: string;
	DateTransferedRegistrationDeadline: any;
	DateUpdated: string;
	DateVerified: string;
	DeletedByFullName: string;
	Description: string;
	DigitalScoreCard_HideCourts: boolean;
	DigitalScoreCard_HideNextUp1: boolean;
	DigitalScoreCard_HideNextUp2: boolean;
	DigitalScoreCard_HideReferees: boolean;
	DisablePartnerManualAdd: boolean;
	EmergencyContactRequired: boolean;
	EventActivityFirstDate: string;
	EventActivityFirstDate_UTC: string;
	EventActivityLastDate: string;
	EventActivityLastDate_UTC: string;
	EventID: string;
	EventIDInt: number;
	EventTypeID: number;
	EventTypeTitle: string;
	FeePayType_Description: string;
	FeePayType_ID: number;
	FeePayType_Title: string;
	FeePlan_BaseServiceAmount: string;
	FeePlan_BaseServiceFeeCap: string;
	FeePlan_BaseServicePercentage: string;
	FeePlan_ForceFeePayTypeID: number;
	FeePlan_ID: number;
	FeePlan_IsAdditionalFeePrizeMinAmountToUse: string;
	FeePlan_IsAdditionalFeeProEvent: boolean;
	FeePlan_IsAdditionalFeeProEventAmount: string;
	FeePlan_MinServiceAmount: string;
	FeePlan_OneTimeSetupFeeActual: string;
	FeePlan_OneTimeSetupFeeAdvertise: string;
	FeePlan_PBMerchant_AdditionalCost: string;
	FeePlan_PBMerchant_AdditionalCostID: number;
	FeePlan_PaymentProcessingPercentage: string;
	FeePlan_PricePerRegistrationActual: string;
	FeePlan_TakePlatformFeeAtPayment: boolean;
	FlyerClickCount: number;
	FlyerContentType: string;
	FlyerExtension: string;
	FlyerFile: string;
	FlyerURL: string;
	Goal_Finacial: string;
	Goal_Registrations: number;
	HealthAndSafety_Details: string;
	HideRegisteredPlayers: boolean;
	HideTourneyOnSearchPage: boolean;
	ImpressionCount: number;
	ImpressionCount_Mobile: number;
	IsClubMembersOnly: boolean;
	IsFeatured: number;
	IsParkingFee: boolean;
	IsPrivateEvent: boolean;
	IsPrivateEventAccessCode: string;
	IsPrizeMoney: boolean;
	IsPrizeMoneyAmount: string;
	IsReadOnlyMode: boolean;
	IsRegisteredFor: number;
	IsSanctioned: boolean;
	IsStub: boolean;
	IsTestEvent: boolean;
	IsWithdrawn: number;
	JuniorDiscount: string;
	LanguageFamilyID: number;
	LoadWaitingListInOrderForPublic: boolean;
	LocationOfEvent_AltName: string;
	LocationOfEvent_BallURL: string;
	LocationOfEvent_BallUsed: string;
	LocationOfEvent_City: string;
	LocationOfEvent_CountryAbbreviation: string;
	LocationOfEvent_CountryID: number;
	LocationOfEvent_CountryStateTitle: string;
	LocationOfEvent_CountryTitle: string;
	LocationOfEvent_CountryZipCodeTitle: string;
	LocationOfEvent_IsParkingFee: boolean;
	LocationOfEvent_IsPrimary: number;
	LocationOfEvent_Latitude: string;
	LocationOfEvent_LocationID: string;
	LocationOfEvent_Longitude: string;
	LocationOfEvent_NetTypeID: number;
	LocationOfEvent_NetTypeTitle: string;
	LocationOfEvent_NumberOfCourts: number;
	LocationOfEvent_ParkingInformation: string;
	LocationOfEvent_PlayAreaTypeID: number;
	LocationOfEvent_PlayAreaTypeTitle: string;
	LocationOfEvent_StateAbbreviation: string;
	LocationOfEvent_StateID: number;
	LocationOfEvent_StateTitle: string;
	LocationOfEvent_StreetAddress: string;
	LocationOfEvent_SurfaceTypeID: number;
	LocationOfEvent_SurfaceTypeTitle: string;
	LocationOfEvent_TotalActive: number;
	LocationOfEvent_Venue: string;
	LocationOfEvent_Zip: string;
	LockedByFullName: string;
	LockedComments: string;
	Logo: string;
	Lottery_DateRegistrationEnds: any;
	Lottery_DateRegistrationEnds_UTC: any;
	Lottery_Description: string;
	Lottery_IsActive: boolean;
	MaxActivitiesPerRegistration: number;
	MaxDiscountAmountUsedOnRegistration: string;
	MaxDiscountCodesUsedOnRegistration: number;
	MaxRegistrationsForEntireEvent: number;
	NumberOfGauranteedGames: number;
	OfficiatedTypeID: number;
	OfficiatedTypeTitle: string;
	OnlineRegistration_Active: boolean;
	OnlineStoreURL: string;
	OnlyAllow_AssociationOfOntarioMember: boolean;
	OnlyAllow_CanadaMember: boolean;
	OnlyAllow_SSIPAMember: boolean;
	OnlyAllow_SSIPAMember_OnlyCertainEvents: boolean;
	OnlyAllow_USAPAMember: boolean;
	ParkingInstructions: string;
	PaymentCurrencyAbbreviation: string;
	PaymentCurrencyFormatTag: string;
	PlayerTextFee_OptIn: number;
	PlayerTextFee_OptIn_ShowFee: boolean;
	PlayersRequireFullAddress: boolean;
	PrintSpoolerPassCode: string;
	PrizeMoneyDescription: string;
	Rating_1stChoice: string;
	Rating_2ndChoice: any;
	Rating_3rdChoice: any;
	Rating_4thChoice: any;
	RefereeAppCode: string;
	RefundPolicy: string;
	Refund_AutomaticallyGive: boolean;
	Refund_CreateProcessingFeePaymentRecord: boolean;
	Refund_DeadlineDate: any;
	Refund_ProcessingFee: string;
	RegistrationClickCount: number;
	RegistrationContentType: string;
	RegistrationCount_InAtLeastOneLiveEvent: number;
	RegistrationCount_NotInAtLeastOneLiveEvent: number;
	RegistrationCount_Withdrawn: number;
	RegistrationExtension: string;
	RegistrationFile: string;
	RegistrationURL: string;
	Registration_DateClosed: string;
	Registration_DateClosed_UTC: string;
	Registration_DateOpen: string;
	Registration_DateOpen_UTC: string;
	RequirePartnerPhoneOnManualEntry: boolean;
	SantionLevelID_Club: any;
	SantionLevelTitle_Club: string;
	ScheduleClickCount: number;
	ScheduleContentType: string;
	ScheduleExtension: string;
	ScheduleFile: string;
	ScheduleURL: string;
	SendPreliminaryToGenius: boolean;
	SendResultsTo_USAPA_UTPR: boolean;
	ShortURL: string;
	ShowDraws: number;
	ShowEmailToTournamentAdmins: boolean;
	ShowPointDifference_Percentage: boolean;
	SportID: string;
	SportTitle: string;
	SportTitleAcronym: string;
	StartTimesPosted: boolean;
	StartTimesToShow: string;
	StepsCompleted: number;
	StopWaitingListService: boolean;
	TextPricePerRegistration: string;
	TicketsURL: string;
	TimeZoneAbbreviation: string;
	TimeZoneID: number;
	TimeZoneIanaID: string;
	TimeZoneTitle: string;
	TimeZoneTitleExtended: string;
	Title: string;
	TotalInDailyPlanner: number;
	TotalIsNonPlayerRecord_ExcludingWithdrawn: number;
	TotalIsNonPlayerRecord_IncludingWithdrawn: number;
	TotalQuestions: number;
	TotalQuestions_Active: number;
	TotalRegistrations: number;
	TurnOffNotifications: boolean;
	TurnOffNotifications_Text: boolean;
	UDF_CountAvailableToGuest: number;
	UDF_PurchaseItemsOnlyButtonText: string;
	URL: string;
	USAPAMemberDiscount: string;
	USAPAMemberDiscountExpireDate: any;
	UpdatedByFullName: string;
	UseClubMemberCost: boolean;
	UseClubMemberCost_EvenIfAfterSignUp: boolean;
	UseOutOfStateCost: boolean;
	UseOutOfStateCost_EvenIfAfterSignUp: boolean;
	UseSeniorAndU50: boolean;
	UsingGenuisSports: boolean;
	VerifiedByFullName: string;
	ViewCount: number;
	ViewCount_Mobile: number;
	WaiverClickCount: number;
	WaiverContentType: string;
	WaiverExtension: string;
	WaiverFile: string;
	WaiverURL: string;
	Weather_Details: string;
	Weather_ShowAlert: boolean;
	WheelchairDiscount: string;
}

export interface TourneyHeaderData {
	Title: string;
	Location: string;
	ImageURL: string;
	StartTimesPosted: boolean;
}

export interface TourneyOverviewDetails {
	Description: string;
	AdditionalInformation: string;
	RefundPolicy: string;
	Weather_Details: string;
	HealthAndSafety_Details: string;
	VenueName: string;
	Location: string;
	SurfaceType: string;
	PlayAreaType: string;
	NetType: string;
	BallUsed: string;
	NumberOfCourts: number;
	IsParkingFee: boolean;
	ParkingInstructions: string;
	ParkingInformation: string;
	OfficiatedTypeTitle: string;
	MaxActivitiesPerRegistration: number;
	Cost_NumberActivitiesIncludedInRegCost: number;
	NumberOfGauranteedGames: number;
	AllowPets: boolean;
	AllowFood: boolean;
	AllowGrill: boolean;
	AllowBringDishToPass: boolean;
	IsCanceled: boolean;
	Weather_ShowAlert: boolean;
}

export interface TourneySidebarDetails {
	ActivateVolunteerSignup: boolean;
	ActivateRefereeSignup: boolean;
	AdvertiseOnly: boolean;
	CancelTourney: boolean;
	Cost_Registration_ClubMember: string;
	Cost_Registration_Current: string;
	Cost_Registration_Current_OutOfState: string;
	DateLocked: string;
	EventActivityFirstDate: string;
	EventActivityFirstDate_UTC: string;
	EventActivityLastDate: string;
	EventActivityLastDate_UTC: string;
	FlyerFile: string;
	HideRegisteredPlayers: boolean;
	IsReadOnlyMode: boolean;
	IsRegisteredFor: number;
	OnlineStoreURL: string;
	MaxRegistrationsForEntireEvent: number;
	PaymentCurrencyAbbreviation: string;
	OnlineRegistration_Active: boolean;
	RegistrationCount_InAtLeastOneLiveEvent: number;
	Registration_DateClosed: string;
	Registration_DateClosed_UTC: string;
	Registration_DateOpen: string;
	Registration_DateOpen_UTC: string;
	TicketsURL: string;
	TimeZoneAbbreviation: string;
	TimeZoneTitle: string;
	TotalRegistrations: number;
	UDF_CountAvailableToGuest: number;
	UDF_PurchaseItemsOnlyButtonText: string;
	URL: string;
}

export const tourneySidebarDetailsTemplate: TourneySidebarDetails = {
	ActivateVolunteerSignup: false,
	ActivateRefereeSignup: false,
	AdvertiseOnly: false,
	CancelTourney: false,
	Cost_Registration_ClubMember: '',
	Cost_Registration_Current: '',
	Cost_Registration_Current_OutOfState: '',
	DateLocked: '',
	EventActivityFirstDate: '',
	EventActivityFirstDate_UTC: '',
	EventActivityLastDate: '',
	EventActivityLastDate_UTC: '',
	FlyerFile: '',
	HideRegisteredPlayers: false,
	IsReadOnlyMode: false,
	IsRegisteredFor: 0,
	OnlineStoreURL: '',
	MaxRegistrationsForEntireEvent: 0,
	PaymentCurrencyAbbreviation: '',
	OnlineRegistration_Active: false,
	RegistrationCount_InAtLeastOneLiveEvent: 0,
	Registration_DateClosed: '',
	Registration_DateClosed_UTC: '',
	Registration_DateOpen: '',
	Registration_DateOpen_UTC: '',
	TicketsURL: '',
	TimeZoneAbbreviation: '',
	TimeZoneTitle: '',
	TotalRegistrations: 0,
	UDF_CountAvailableToGuest: 0,
	UDF_PurchaseItemsOnlyButtonText: '',
	URL: ''
};

export interface TourneyCost {
	Cost_Registration: string;
	Cost_Registration_ClubMember: string;
	Cost_Registration_OutOfState: string;
	CreatedByFullName: string;
	DateCreated: string;
	DateStart: any;
	DateStart_UTC: any;
	DateUpdated: string;
	EventID: string;
	IsCurrent: number;
	IsLocked: number;
	RegistrationCostID: string;
	TimeZoneUTCOffset_AsDisplay: number;
	Title: string;
	UpdatedByFullName: string;
	ord: number;
}

export interface MyTournament {
	AcceptedPayment_PayPal_Active: boolean;
	ActivateRefereeSignup: boolean;
	ActivateVolunteerSignup: boolean;
	ActivityTotal: number;
	AdvertiseOnly: boolean;
	AttendeeHeaderID: string;
	AttendeeTotal: number;
	Caption: string;
	ClubID: string;
	Coo_CanManageActivities: number;
	Coo_CanManageAttendees: number;
	Coo_CanManageCheckin: number;
	Coo_CanManageEvent: number;
	Coo_CanManagePayments: number;
	Coo_CanManageReferees: number;
	Coo_CanManageScores: number;
	Coo_CanManageVolunteers: number;
	Coo_CanViewOnly: number;
	Coo_CanViewReports: number;
	Coo_IsPrimary: number;
	Cost_FeeOnEvents: string;
	Cost_Registration_Current: string;
	DateFrom: string;
	DateLocked: any;
	DateTo: string;
	DisplayArea: string;
	EventSetup_StepsCompleted: number;
	HideRegisteredPlayers: boolean;
	ID: string;
	IsCanceled: boolean;
	IsFavorite: number;
	IsLocked: number;
	IsNonPlayerRecord: boolean;
	IsReadOnlyMode: boolean;
	IsRegClosed: number;
	IsStub: boolean;
	LocationOfEvent_City: string;
	LocationOfEvent_CountryAbbreviation: string;
	LocationOfEvent_StateAbbreviation: string;
	LocationOfEvent_StreetAddress: string;
	LocationOfEvent_Venue: string;
	LocationOfEvent_Zip: string;
	Logo: string;
	ModuleID: any;
	OnlineRegistration_Active: boolean;
	PaymentCurrencyAbbreviation: string;
	RegistrationCount: number;
	Registration_DateClosed: string;
	Registration_DateOpen: string;
	SponsorLogo: any;
	SponsorTitle: any;
	Tagline: any;
	TimeZone: string;
	TimeZoneHasDST: boolean;
	TimeZoneIanaID: string;
	TimeZoneName: string;
	TimeZoneTitleExtended: string;
	TimeZoneUTCOffset: number;
	Title: string;
	TitleSponsor_Logo: string;
	TitleSponsor_Tagline: string;
	TitleSponsor_Title: string;
	TitleSponsor_URL: string;
	TotalOwe: string;
	Tourney_Managing_CanManageActivities: number;
	Tourney_Managing_CanManageAttendees: number;
	Tourney_Managing_CanManageCheckin: number;
	Tourney_Managing_CanManageEvent: number;
	Tourney_Managing_CanManagePayments: number;
	Tourney_Managing_CanManageReferees: number;
	Tourney_Managing_CanManageScores: number;
	Tourney_Managing_CanManageVolunteers: number;
	Tourney_Managing_IsPrimary: number;
	URL: any;
	showResultsButton: number;
}

export interface LodgingInfo {
	AmenityTypeID: number;
	Venue: string;
	URL: string;
	StreetAddress: string;
	City: string;
	StateTitle: string;
	Zip: string;
	CountryTitle: string;
	Details: string;
}

export interface TeamInQueue {
	ActivityTitle: string;
	DidPrintNextAvailable_DatePrinted: string;
	DidPrintNextAvailable_DatePrinted_UTC: string;
	DisplayMatchNumber: number;
	EventTitle: string;
	P1_FirstName: string;
	P1_LastName: string;
	P1_NameSuffix: string;
	P1_Phone: string;
	P1_Phone_AreaCode: string;
	P1_Phone_CallingCode: string;
	P1_UserID: string;
	P2_FirstName: string;
	P2_LastName: string;
	P2_NameSuffix: string;
	P2_Phone: string;
	P2_Phone_AreaCode: string;
	P2_Phone_CallingCode: string;
	P2_UserID: string;
	ScoreID: string;
	Tag_Translations: string;
	TeamID: string;
	UpNext_CourtLocationTitle: string;
	UpNext_CourtTitle: string;
}

export interface TeamInQueueInfo {
	title: string;
	player1: string;
	player2: string;
	matchNum: number;
	time: string;
}

export interface GameOnCourt {
	ActivityID: string;
	ActivityTitle: string;
	CoutTitle: string;
	DateStart: string;
	DateStart_UTC: string;
	DisplayMatchNumber: number;
	ElapsedTime_InMinute: number;
	EsimatedMinutes: number;
	EventTitle: string;
	FormatID: number;
	FormatTitle: string;
	InBracketType: string;
	IsNextCount: number;
	LocationAltName: string;
	LocationID: string;
	LocationVenue: string;
	PlayerGroupID: number;
	PlayerGroupTitle: string;
	PoolNumber: number;
	Referee_Name: string;
	ScoreFormat: string;
	ScoreID: string;
	T1_P1_FirstName: string;
	T1_P1_LastName: string;
	T1_P1_NameSuffix: string;
	T1_P1_UserID: string;
	T1_P2_FirstName: string;
	T1_P2_LastName: string;
	T1_P2_NameSuffix: string;
	T1_P2_UserID: string;
	T2_P1_FirstName: string;
	T2_P1_LastName: string;
	T2_P1_NameSuffix: string;
	T2_P1_UserID: string;
	T2_P2_FirstName: string;
	T2_P2_LastName: string;
	T2_P2_NameSuffix: string;
	T2_P2_UserID: string;
	Tag_Translations: string;
	Team1_Name: string;
	Team2_Name: string;
	TeamID_1: string;
	TeamID_2: string;
	TimeZoneAbbreviation: string;
	TimeZoneTitleExtended: string;
	TotalLocations: number;
	TotalPools: number;
}

export interface GameOnCourtInfo {
	title: string;
	courtTitle: string;
	team1: string;
	team2: string;
	startTime: string;
	elapsedTime: string;
	scoreFormat: string;
	locationInfo: string;
}

export interface GoverningBodyInfo {
	ClubID: string;
	ClubTitle: string;
	DisplayOrder: number;
	Level_ID: string;
	Level_Logo: string;
	Level_Title: string;
}

export interface TournamentSearchData {
	slug: string;
	titleSponsorLogo: string;
	titleSponsorUrl: string;
	titleSponsorTitle: string;
	CancelTourney: boolean;
	TourneyToDate: string;
	TimeZoneUTCOffset: number;
	IsRegClosed: boolean;
	CostRegistrationCurrent: number;
	PaymentCurrencyAbbreviation: string;
	CostFeeOnEvents: string;
	ClubID: string;
	TournamentID: string;
	IsFeatured: boolean;
	Logo: string;
	IsPrizeMoney: boolean;
	TourneyFromDate: string;
	Title: string;
	LocationCity: string;
	LocationState: string;
	LocationCountry: string;
	GoverningBodiesData: GoverningBodyInfo[];
	hideRegisteredPlayers: boolean;
	RegistrationCount: number;
	waitingListCount?: number;
}

export const tournamentSearchDataTemplate: TournamentSearchData = {
	CancelTourney: false,
	TourneyToDate: '',
	TimeZoneUTCOffset: 0,
	IsRegClosed: false,
	CostRegistrationCurrent: 0,
	PaymentCurrencyAbbreviation: '',
	CostFeeOnEvents: '',
	ClubID: '',
	TournamentID: '',
	IsFeatured: false,
	Logo: '',
	IsPrizeMoney: false,
	TourneyFromDate: '',
	Title: '',
	LocationCity: '',
	LocationState: '',
	LocationCountry: '',
	GoverningBodiesData: [],
	hideRegisteredPlayers: false,
	RegistrationCount: 0,
	slug: '',
	titleSponsorLogo: '',
	titleSponsorUrl: '',
	titleSponsorTitle: ''
};

export interface TeamsInEventData {
	AttendeeActivityID: string;
	AttendeeIsOnHoldList: number;
	Partner_IsOnHoldList: number;
	AttendeeRankToDisplay: string;
	AttendeeFirstName: string;
	AttendeeGender: string;
	AttendeeAge: number;
	AttendeeCity: string;
	AttendeeState: string;
	AttendeeClubTitle: string;
	Partner_RegisteredForEvent: boolean;
	Partner_FirstName: string;
	Partner_RankToDisplay: string;
	Partner_UserID: string;
	Partner_Gender: string;
	PartnerAge: number;
	Partner_City: string;
	Partner_State: string;
	Partner_ClubTitle: string;
	AttendeeLastName: string;
	Partner_LastName: string;
	NeedPartner: boolean;
	AttendeeUserID: string;
	AttendeeShowAgeToPublic: boolean;
	Partner_ShowAgeToPublic: boolean;
	AttendeeShowCityToPublic: boolean;
	AttendeeShowStateToPublic: boolean;
	Partner_ShowCityToPublic: boolean;
	Partner_ShowStateToPublic: boolean;
	AttendeeDUPRActive: boolean;
	ParnterDUPRActive: boolean;
	AttendeeSLUG: string;
	Partner_SLUG: string;
}

export const teamsInEventTemplate: TeamsInEventData = {
	AttendeeActivityID: '',
	AttendeeIsOnHoldList: 0,
	Partner_IsOnHoldList: 0,
	AttendeeRankToDisplay: '',
	AttendeeFirstName: '',
	AttendeeGender: '',
	AttendeeAge: 0,
	AttendeeCity: '',
	AttendeeState: '',
	AttendeeClubTitle: '',
	Partner_RegisteredForEvent: false,
	Partner_FirstName: '',
	Partner_RankToDisplay: '',
	Partner_UserID: '',
	Partner_Gender: '',
	PartnerAge: 0,
	Partner_City: '',
	Partner_State: '',
	Partner_ClubTitle: '',
	AttendeeLastName: '',
	Partner_LastName: '',
	NeedPartner: false,
	AttendeeUserID: '',
	AttendeeShowAgeToPublic: false,
	Partner_ShowAgeToPublic: false,
	AttendeeShowCityToPublic: false,
	AttendeeShowStateToPublic: false,
	Partner_ShowCityToPublic: false,
	Partner_ShowStateToPublic: false,
	AttendeeDUPRActive: false,
	ParnterDUPRActive: false,
	AttendeeSLUG: '',
	Partner_SLUG: ''
};

export interface TourneyEventData {
	ActivitySplitID: string;
	DateOfActivity_UTC: string;
	ActivityID: string;
	HidePlayers: boolean;
	GoLiveStatusID: number;
	TotalPlayedMatches: number;
	PlayerGroupID: string;
	FormatID: number;
	StartTimesPosted: boolean;
	DateOfActivity_ToUse: string;
	Title: string;
	BracketFormatTitle: string;
	ShowActivityDraws: boolean;
	MaxAttendies: number;
	TeamCountWhereBothAreRegistered: number;
	TeamCountWhereOnlyOneIsRegistered: number;
	TotalRegistrations: number;
	MaxWaitingList: number;
	TeamCountWhereBothAreRegistered_OnHold: number;
	TeamCountWhereOnlyOneIsRegistered_OnHold: number;
	TotalRegistrations_OnHold: number;
	TeamCountWhereBothAreRegistered_OnHold_Lottery: number;
	TeamCountWhereOnlyOneIsRegistered_OnHold_Lottery: number;
	TotalRegistrations_OnHold_Lottery: number;
	DateEndActivity: string;
	IsMedalAtActityLevel: boolean;
	IsMedalFromAnotherPlayedBracket: boolean;
	NoMedalWasAwarded: boolean;
	GoldMedal_Team: string;
	SilverMedal_Team: string;
	BronzeMedal_Team: string;
	BracketLevelID: number;
	DateOfActivity: string;
	RankRangeForUI: string;
	CanPlayersPlayUpRanking: boolean;
	AgeRangeForUI: string;
	PlayerGroupTitle: string;
	FormatTitle: string;
	DateOfActivityShowTime: boolean;
	DateOfActivityShowTimeOfDay: string;
	DateToDisplay: string;
	BracketLevelTitle: string;
	CustomMatchDate_Bronze: string;
	CustomMatchDate_Gold: string;
	CustomMatchDate_QuarterFinals: string;
	CustomMatchDate_Round16: string;
	CustomMatchDate_Round32: string;
	CustomMatchDate_Round64: string;
	CustomMatchDate_SemiFinals: string;
}

export const tourneyEventDataTemplate: TourneyEventData = {
	PlayerGroupTitle: '',
	FormatTitle: '',
	BracketLevelTitle: '',
	ActivityID: '',
	HidePlayers: false,
	GoLiveStatusID: 0,
	TotalPlayedMatches: 0,
	PlayerGroupID: '',
	FormatID: 0,
	StartTimesPosted: false,
	DateOfActivity_ToUse: '',
	Title: '',
	BracketFormatTitle: '',
	ShowActivityDraws: false,
	MaxAttendies: 0,
	TeamCountWhereBothAreRegistered: 0,
	TeamCountWhereOnlyOneIsRegistered: 0,
	TotalRegistrations: 0,
	MaxWaitingList: 0,
	TeamCountWhereBothAreRegistered_OnHold: 0,
	TeamCountWhereOnlyOneIsRegistered_OnHold: 0,
	TotalRegistrations_OnHold: 0,
	TeamCountWhereBothAreRegistered_OnHold_Lottery: 0,
	TeamCountWhereOnlyOneIsRegistered_OnHold_Lottery: 0,
	TotalRegistrations_OnHold_Lottery: 0,
	DateEndActivity: '',
	IsMedalAtActityLevel: false,
	IsMedalFromAnotherPlayedBracket: false,
	NoMedalWasAwarded: false,
	GoldMedal_Team: '',
	SilverMedal_Team: '',
	BronzeMedal_Team: '',
	BracketLevelID: 0,
	DateOfActivity: '',
	RankRangeForUI: '',
	CanPlayersPlayUpRanking: false,
	AgeRangeForUI: '',
	DateOfActivity_UTC: '',
	DateOfActivityShowTime: false,
	DateOfActivityShowTimeOfDay: '',
	DateToDisplay: '',
	ActivitySplitID: '',
	CustomMatchDate_Bronze: '',
	CustomMatchDate_Gold: '',
	CustomMatchDate_QuarterFinals: '',
	CustomMatchDate_Round16: '',
	CustomMatchDate_Round32: '',
	CustomMatchDate_Round64: '',
	CustomMatchDate_SemiFinals: ''
};

export interface FormattedEvent {
	groupName: string;
	events: TourneyEventData[];
}

export interface TourneyCostData {
	Cost_Registration: string;
	Cost_Registration_ClubMember: string;
	Cost_Registration_OutOfState: string;
	ord: number;
	Title: string;
	DateStart: string;
}

export const tourneyCostTemplate: TourneyCostData = {
	Cost_Registration: '',
	Cost_Registration_ClubMember: '',
	Cost_Registration_OutOfState: '',
	ord: 0,
	Title: '',
	DateStart: ''
};

export interface SponsorData {
	Logo: string;
	ModuleID: string;
	SponsorLevelTitle: string;
	SponsorLevelID: number;
	FontColorCode: string;
	BGColorCode: string;
}

export const sponsorDataTemplate: SponsorData = {
	Logo: '',
	ModuleID: '',
	SponsorLevelTitle: '',
	SponsorLevelID: 0,
	FontColorCode: '',
	BGColorCode: ''
};

export interface PlayerTournamentData extends TournamentSearchData {
	GoverningBodies?: TourneyGoverningBodies[];
	waitingListCount?: number;
}

export const playerTournamentTemplate: PlayerTournamentData = {
	GoverningBodies: [],
	slug: '',
	titleSponsorLogo: '',
	titleSponsorUrl: '',
	titleSponsorTitle: '',
	CancelTourney: false,
	TourneyToDate: '',
	TimeZoneUTCOffset: 0,
	IsRegClosed: false,
	CostRegistrationCurrent: 0,
	PaymentCurrencyAbbreviation: '',
	CostFeeOnEvents: '',
	ClubID: '',
	TournamentID: '',
	IsFeatured: false,
	Logo: '',
	IsPrizeMoney: false,
	TourneyFromDate: '',
	Title: '',
	LocationCity: '',
	LocationState: '',
	LocationCountry: '',
	GoverningBodiesData: [],
	hideRegisteredPlayers: false,
	RegistrationCount: 0,
	waitingListCount: 0
};

export interface RecommendedTournament {
	tournament_id: string;
	tourney_from_date: string;
	tourney_to_date: string;
	is_reg_closed: boolean;
	logo: string;
	registration_date_closed: string;
	registration_date_open: string;
	title: string;
	slug: string;
	location_city: string;
	location_country: string;
	location_state: string;
	registration_count: string;
	time_zone_utc_offset: number;
	cost_registration_current: string;
	payment_currency_abbreviation: string;
	cost_fee_on_events: string;
}

export interface IsManagerDetails {
	IsManager: boolean;
	IsRegistered: boolean;
}

export const isManagerDetailsTemplate: IsManagerDetails = {
	IsManager: false,
	IsRegistered: false
};
