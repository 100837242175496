import { useQuery } from '@tanstack/react-query';
import Image from 'next/image';

import { ImpressionWrapperPages } from '@/components/ImpressionWrapper/ImpressionWrapperPages';
import { HomePageMarketingType } from '@/modules/tourney/homepage/types';

export interface HPMarketingProps {
	isWhite?: boolean;
	hpMarketingType: number;
	moduleFor: number;
}

const HPMarketingSection = ({ isWhite, hpMarketingType, moduleFor }: HPMarketingProps) => {
	const titleLookup: { [key: number]: string } = {
		[HomePageMarketingType.HPM_PARTNERS_COLUMN]: 'Partners',
		[HomePageMarketingType.HPM_TOURS_SERIES_COLUMN]: 'Tours & Series',
		[HomePageMarketingType.HPM_ASSOCIATIONS_COLUMN]: 'Associations'
	};

	const title = titleLookup[hpMarketingType] || '';

	const queryKey = title
		.toLowerCase()
		.replace(/[^a-z0-9 ]/g, '')
		.replace(/\s+/g, '_');

	const { data } = useQuery(
		[queryKey],
		async () => {
			const params = new URLSearchParams({
				status: 'live',
				retrieve_all: 'true',
				sort_by: 'display_order',
				order_by: 'asc'
			});

			const queryString = encodeURIComponent(params.toString());

			const url = `/api/hpMarketing/getModules?hpMarketingType=${hpMarketingType}&moduleFor=${moduleFor}&queryString=${queryString}`;

			const response = await fetch(url);

			const data: { websiteUrl: string; logo: string; hpMarketingUuid: string }[] = await response.json();
			return data;
		},
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true
		}
	);
	return (
		<>
			{data && data?.length > 0 && (
				<div className={`${isWhite ? 'bg-white' : ''} py-8`}>
					<div className="container mx-auto flex max-w-screen-2xl flex-row items-baseline justify-between py-4">
						<h1 className="text-2xl font-bold text-gray-900" tabIndex={0}>
							{title}
						</h1>
					</div>
					<div className="container mx-auto grid max-w-screen-2xl grid-cols-3 gap-4 py-4 md:gap-10 lg:grid-cols-5 xl:grid-cols-7">
						{data.map((module, i) => (
							<ImpressionWrapperPages moduleId={module.hpMarketingUuid} moduleFor={hpMarketingType} key={i}>
								<div
									className="relative h-32 w-full rounded-lg border bg-white"
									tabIndex={0}
									role="button"
									aria-label={`Parnter ${i} container`}
								>
									<a href={module.websiteUrl} target="_blank" aria-label={`Link to Partner ${i}`}>
										<Image
											src={module.logo}
											alt={`Logo for ${module.websiteUrl}`}
											fill
											priority
											className="rounded-lg object-contain p-2"
											sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
										/>
									</a>
								</div>
							</ImpressionWrapperPages>
						))}
					</div>
				</div>
			)}
		</>
	);
};

export default HPMarketingSection;
