import { TournamentFlatDataSearch } from '@pickleballinc/react-ui';
import axios from 'axios';

import { TournamentSearchData, tournamentSearchDataTemplate } from '@/types/tournaments.types';
import { mapToTemplate } from '@/utils/mapToTemplate';

export const tournamentToShowFirstUUID = '4b073aa7-e67f-43f4-9fcb-cab0fff733fe';

export const getPromotionModifiedTournaments = async (mappedTournaments: TournamentSearchData[]) => {
	const { API_KEY, PROXY_TARGET_URL } = process.env;

	const currentDate = new Date();
	const promotionEndDate = new Date(Date.UTC(2024, 9, 7, 5, 0, 0));

	if (currentDate < promotionEndDate) {
		// If the UPA World Championship is already in payload
		let firstTournament: TournamentSearchData | undefined = mappedTournaments.find(
			(tournament) => tournament.TournamentID === tournamentToShowFirstUUID
		);

		if (firstTournament) {
			const otherTournaments = mappedTournaments.filter((tournament) => tournament.TournamentID !== tournamentToShowFirstUUID);
			return [firstTournament, ...otherTournaments];
		}

		const firstTournamentPayload = await axios.get<{
			result: TournamentFlatDataSearch[];
			totalRecords: number;
		}>(`${PROXY_TARGET_URL}/v1/data/tourney_flat_data?tournament_uuid=${tournamentToShowFirstUUID}`, {
			headers: { 'PB-API-TOKEN': API_KEY }
		});

		firstTournament = mapToTemplate(firstTournamentPayload.data.result[0], tournamentSearchDataTemplate);

		return [firstTournament, ...mappedTournaments];
	}

	return mappedTournaments;
};
