import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteResponse } from '@pickleballinc/lib/server/next/route/types';
import { Button } from '@pickleballinc/react-ui';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import classNames from 'classnames';
import moment from 'moment';
import { Url } from 'next/dist/shared/lib/router/router';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useRef, useState } from 'react';
import React from 'react';

import { Skeleton } from '@/components/EventCard/Skeleton';
import { ImpressionWrapperPages } from '@/components/ImpressionWrapper/ImpressionWrapperPages';
import { RenderAd } from '@/components/RenderAd';
import TournamentsCustomAd from '@/components/RenderAd/TournamentsCustomAd';
import { useAuth } from '@/contexts/auth/session';
import { useGetCreatePermissions } from '@/hooks/useGetCreatePermissions';
import { tournamentToShowFirstUUID } from '@/modules/tourney/homepage/helpers';
import HPMarketingSection from '@/modules/tourney/homepage/HPMarketingSection';
import { HomePageMarketingType, SystemModuleType } from '@/modules/tourney/homepage/types';
import { PPATourneysCarousel } from '@/modules/tourney/list/components/PPATourneyCarousel';
import { renderTourneyCardLabels } from '@/modules/tourney/list/TourneysList';
import { FeaturedTournamentsRes } from '@/pages/api/tourneys/getFeaturedTournaments';
import { RecommendedTournamentsRes } from '@/pages/api/tourneys/getRecommendedTournaments';
import { GetTournamentsRes } from '@/pages/api/tourneys/getTournaments';
import getPBracketsCDNURL from '@/utils/getPBracketsCDNURL';
import isSsoURL from '@/utils/isSsoURL';
import { IpApi } from '@/utils/types';

export const Homepage = ({
	ipAddress,
	ipApi,
	redirectToNewDetailsPage = false
}: {
	ipAddress?: string;
	ipApi?: IpApi | null;
	redirectToNewDetailsPage?: boolean;
}) => {
	const [createATournamentURL, setCreateATournamentURL] = useState<Url>('');
	const [registeredForURL, setRegisteredForURL] = useState<Url>('');
	const [managingURL, setManagingURL] = useState<Url>('');

	const { user } = useAuth();
	const { data: createPerms } = useGetCreatePermissions('Tourney', !!user?.session.uuid);

	// Managing URL
	useEffect(() => {
		const destinationUrl = new URL(`${process.env.NEXT_PUBLIC_PT_URL}/search?my_tournaments=true&managing=true`);

		if (user?.session && user?.session?.uuid) {
			setManagingURL(destinationUrl.toString());
		} else {
			const url = new URL(`${process.env.NEXT_PUBLIC_SSO_ENDPOINT}/v3/signin`);
			url.searchParams.set('flow', 'SelfService');
			url.searchParams.set('continue', destinationUrl.toString());
			setManagingURL(url);
		}
	}, []);

	useEffect(() => {
		const destinationUrl = new URL(`${process.env.NEXT_PUBLIC_PT_URL}/search?my_tournaments=true`);

		if (user?.session && user?.session?.uuid) {
			setRegisteredForURL(destinationUrl.toString());
		} else {
			const url = new URL(`${process.env.NEXT_PUBLIC_SSO_ENDPOINT}/v3/signin`);
			url.searchParams.set('flow', 'SelfService');
			url.searchParams.set('continue', destinationUrl.toString());
			setRegisteredForURL(url);
		}
	}, []);

	// Create a Tournament URL
	useEffect(() => {
		if (user?.session && user?.session?.uuid && createPerms?.ApprovedToCreate) {
			const url = new URL(createPerms.URLPath);
			url.searchParams.set('rurl', window.location.href);
			setCreateATournamentURL(url);
		} else {
			const url = new URL(`https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}`);

			url.hostname = `contact.${url.hostname}`;
			url.pathname += 'contacts/tournament/create';

			setCreateATournamentURL(url);
		}
	}, [createPerms]);

	const { data: recommended, isLoading: isRecommendedLoading } = useQuery(
		['tourneys_recommended'],
		async () => {
			const params = new URLSearchParams({
				// 200 miles
				range_km: '322'
			});

			const urlCompose = params.toString();

			const response = await axios.get<RouteResponse<RecommendedTournamentsRes>>('/api/tourneys/getRecommendedTournaments', {
				params: { queryString: urlCompose }
			});

			return {
				data: response.data.data
			};
		},
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true
		}
	);

	const initialFeaturedTournamentsCount = 12;

	const { data: featuredTournaments, isLoading: isFeaturedLoading } = useQuery(
		['tourneys_featured'],
		async () => {
			const params = new URLSearchParams({
				current_page: '1',
				status: 'active',
				sort_by: 'display_order',
				order_by: 'asc'
			});

			params.append('page_size', initialFeaturedTournamentsCount.toString());

			const urlCompose = params.toString();

			const response = await axios.get<RouteResponse<FeaturedTournamentsRes>>('/api/tourneys/getFeaturedTournaments', {
				params: { queryString: urlCompose }
			});

			return {
				data: response.data.data
			};
		},
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true
		}
	);

	const { data: nowPlaying, isLoading: isNowPlayingLoading } = useQuery(
		['tourneys_now_playing'],
		async () => {
			const params = new URLSearchParams({
				now_playing: 'true',
				hide_pt: 'true'
			});

			const urlCompose = params.toString();

			const response = await axios.get<RouteResponse<GetTournamentsRes>>('/api/tourneys/getTournaments', {
				params: { queryString: urlCompose }
			});

			return {
				data: response.data.data
			};
		},
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true
		}
	);

	const { data: nowRegistering, isLoading: isNowRegisteringLoading } = useQuery(
		['tourneys_now_registering'],
		async () => {
			const params = new URLSearchParams({
				now_registering: 'true',
				hide_pt: 'true'
			});

			const urlCompose = params.toString();

			const response = await axios.get<RouteResponse<GetTournamentsRes>>('/api/tourneys/getTournaments', {
				params: { queryString: urlCompose }
			});

			return {
				data: response.data.data
			};
		},
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true
		}
	);

	const router = useRouter();

	const handleCardClick = async (e: React.MouseEvent, slugOrTournamentId: string) => {
		e.preventDefault();
		const url = `${process.env.NEXT_PUBLIC_PT_URL}${redirectToNewDetailsPage ? '/tournament/' : '/tournaments/'}${slugOrTournamentId}`;
		setTimeout(() => {
			window.location.href = url;
		}, 100);
	};

	return (
		<>
			<div className="bg-white">
				<RenderAd zoneId="679340" pageId={100003} place={0} ipAddress={ipAddress} containerClassname="mx-0.5 mt-4 sm:container sm:mx-auto" />

				<div className="mt-4 border-t">
					<div className="container mx-auto grid max-w-screen-2xl grid-cols-2 gap-4 py-4 sm:grid-cols-6 sm:items-center md:flex md:grid-cols-1 md:flex-row md:flex-wrap">
						<a
							href={`${process.env.NEXT_PUBLIC_PT_URL}/search`}
							className={`col-span-2 ${user?.session.uuid ? 'sm:col-span-6' : 'sm:col-span-3'}`}
							aria-label="Find a Tournament"
						>
							<Button size="xl" variant="blue" className="w-full justify-center font-bold" aria-hidden="true">
								Find a Tournament
							</Button>
						</a>
						<>
							<a
								className="col-span-1 sm:col-span-3"
								href={managingURL.toString()}
								rel={isSsoURL(managingURL) ? 'nofollow' : undefined}
								aria-label="Managing a Tournament"
							>
								<Button size="xl" variant="secondary" className="w-full justify-center font-bold" aria-hidden="true">
									Managing
								</Button>
							</a>
							<a
								className="col-span-1 sm:col-span-3"
								href={registeredForURL.toString()}
								rel={isSsoURL(registeredForURL) ? 'nofollow' : undefined}
								aria-label="Registered For a Tournament"
							>
								<Button size="xl" variant="secondary" className="w-full justify-center font-bold" aria-hidden="true">
									Registered For
								</Button>
							</a>
						</>

						{/* <Button variant="secondary" size="xl">
							<div>
								<FontAwesomeIcon icon={faHeart} />
							</div>
						</Button> */}

						<Link
							className={`col-span-2 md:ml-auto ${user?.session.uuid ? 'sm:col-span-6' : 'sm:col-span-3'}`}
							href={createATournamentURL}
							rel={isSsoURL(createATournamentURL) ? 'nofollow' : undefined}
							aria-label="Create a Tournament"
						>
							<Button
								size="xl"
								className="w-full justify-center"
								variant={user?.session.uuid ? 'blue' : 'secondary'}
								aria-hidden="true"
							>
								Create a Tournament
							</Button>
						</Link>
					</div>
				</div>

				{isNowPlayingLoading || isNowRegisteringLoading || isRecommendedLoading || isFeaturedLoading ? (
					<div className="container mx-auto mb-10 grid max-w-screen-2xl grid-cols-2 gap-4 py-4 md:gap-10 lg:grid-cols-4 xl:grid-cols-5">
						{Array.from(Array(10).keys()).map((index) => {
							return <Skeleton key={index} />;
						})}
					</div>
				) : (
					<>
						{recommended?.data && recommended?.data.tournaments.length > 0 && (
							<SliderSection
								redirectToNewDetailsPage={redirectToNewDetailsPage}
								customClassName="border-y bg-gray-50"
								title="Recommended Tournaments"
								subtitle={`${(recommended.data.foundAtRadiusInKm / 1.609344).toFixed(0)} mile radius from ${ipApi?.city}, ${ipApi?.region}`}
								data={recommended?.data.tournaments.map((tourney) => {
									return {
										isRecommendedTournament: true,
										title: tourney.Title,
										image: getPBracketsCDNURL(tourney.Logo, 243, 192),
										duration: `${moment.parseZone(tourney.TourneyFromDate).format('MMM DD, YYYY')} - ${moment
											.parseZone(tourney.TourneyToDate)
											.format('MMM DD, YYYY')}`,
										slug: tourney.slug,
										tournamentId: tourney.TournamentID,
										location: `${tourney.LocationCity}, ${tourney.LocationState}, ${tourney.LocationCountry}`,
										label: renderTourneyCardLabels(tourney, true, true),
										registrationCount: tourney.RegistrationCount,
										isPromoted: tourney.TournamentID === tournamentToShowFirstUUID
									} as TournamentHomeCardProps;
								})}
								renderCardAd
								ipAddress={ipAddress}
							/>
						)}

						{featuredTournaments && featuredTournaments.data.result.length > 0 && (
							<div className="py-8">
								<div className="container mx-auto flex max-w-screen-2xl flex-row items-baseline justify-between py-4">
									<h1 className="text-2xl font-bold text-gray-900" tabIndex={0}>
										Featured Tournaments
									</h1>
									{featuredTournaments.data.totalRecords >= initialFeaturedTournamentsCount && (
										<a href={`${process.env.NEXT_PUBLIC_PT_URL}/search?featured=true`}>
											<Button className="!text-blue-500" variant="link">
												Show all
											</Button>
										</a>
									)}
								</div>
								<div className="container mx-auto grid max-w-screen-2xl grid-cols-1 items-stretch gap-4 py-4 sm:grid-cols-2 md:gap-8 lg:grid-cols-3 xl:grid-cols-4">
									{featuredTournaments.data.result.map((tourney, index) => (
										<Link
											key={index}
											onClick={(e) => handleCardClick(e, tourney.slug || tourney.TournamentID)}
											href={`${process.env.NEXT_PUBLIC_PT_URL}${redirectToNewDetailsPage ? '/tournament/' : '/tournaments/'}${tourney.slug || tourney.TournamentID}`}
										>
											<FeaturedTournamentCard
												image={getPBracketsCDNURL(tourney.Logo, 243, 192)}
												title={tourney.Title}
												duration={`${moment.parseZone(tourney.TourneyFromDate).format('MMM DD, YYYY')} - ${moment
													.parseZone(tourney.TourneyToDate)
													.format('MMM DD, YYYY')}`}
												location={`${tourney.LocationCity}, ${tourney.LocationState}, ${tourney.LocationCountry}`}
												tournamentId={tourney.TournamentID}
											/>
										</Link>
									))}
								</div>
							</div>
						)}

						<PPATourneysCarousel
							isHomepage={true}
							onEventClick={(tourney) =>
								router.push(
									`${process.env.NEXT_PUBLIC_PT_URL}${redirectToNewDetailsPage ? '/tournament/' : '/tournaments/'}${tourney.slug || tourney.TournamentID}`
								)
							}
						/>

						{nowRegistering?.data.result && nowRegistering?.data.result.length > 0 && (
							<SliderSection
								redirectToNewDetailsPage={redirectToNewDetailsPage}
								showAllRedirectURL={`${process.env.NEXT_PUBLIC_PT_URL}/search?now_registering=true`}
								customClassName="border-b"
								title="Now Registering"
								data={nowRegistering?.data.result.map((tourney) => {
									return {
										title: tourney.Title,
										image: getPBracketsCDNURL(tourney.Logo, 243, 192),
										duration: `${moment.parseZone(tourney.TourneyFromDate).format('MMM DD, YYYY')} - ${moment
											.parseZone(tourney.TourneyToDate)
											.format('MMM DD, YYYY')}`,
										slug: tourney.slug,
										tournamentId: tourney.TournamentID,
										location: `${tourney.LocationCity}, ${tourney.LocationState}, ${tourney.LocationCountry}`,
										label: renderTourneyCardLabels(tourney, true, true),
										registrationCount: tourney.RegistrationCount,
										isPromoted: tourney.TournamentID === tournamentToShowFirstUUID
									} as TournamentHomeCardProps;
								})}
							/>
						)}

						{nowPlaying?.data.result && nowPlaying?.data.result.length > 0 && (
							<SliderSection
								redirectToNewDetailsPage={redirectToNewDetailsPage}
								showAllRedirectURL={`${process.env.NEXT_PUBLIC_PT_URL}/search?now_playing=true`}
								title="Now Playing"
								data={nowPlaying?.data.result.map((tourney) => {
									return {
										title: tourney.Title,
										image: getPBracketsCDNURL(tourney.Logo, 243, 192),
										duration: `${moment.parseZone(tourney.TourneyFromDate).format('MMM DD, YYYY')} - ${moment
											.parseZone(tourney.TourneyToDate)
											.format('MMM DD, YYYY')}`,
										slug: tourney.slug,
										tournamentId: tourney.TournamentID,
										location: `${tourney.LocationCity}, ${tourney.LocationState}, ${tourney.LocationCountry}`,
										label: renderTourneyCardLabels(tourney, true, true),
										registrationCount: tourney.RegistrationCount
									} as TournamentHomeCardProps;
								})}
							/>
						)}
					</>
				)}
			</div>

			<HPMarketingSection
				hpMarketingType={HomePageMarketingType.HPM_TOURS_SERIES_COLUMN}
				moduleFor={SystemModuleType.TOURNAMENT_SYSTEM_MODULE_TYPE}
			/>

			<HPMarketingSection
				hpMarketingType={HomePageMarketingType.HPM_ASSOCIATIONS_COLUMN}
				moduleFor={SystemModuleType.TOURNAMENT_SYSTEM_MODULE_TYPE}
				isWhite={true}
			/>

			<HPMarketingSection
				hpMarketingType={HomePageMarketingType.HPM_PARTNERS_COLUMN}
				moduleFor={SystemModuleType.TOURNAMENT_SYSTEM_MODULE_TYPE}
			/>
		</>
	);
};

interface TournamentHomeCardProps {
	isRecommendedTournament?: boolean;
	image: string;
	title: string;
	duration?: string;
	slug?: string;
	tournamentId: string;
	location?: string;
	label?: ReactNode;
	registrationCount?: number;
	isPromoted?: boolean;
}

const TournamentHomeCard = ({ image, title, duration, location, label, registrationCount, isPromoted }: TournamentHomeCardProps) => {
	return (
		<>
			<div
				className="relative flex size-full flex-col rounded-lg border bg-white"
				style={{
					boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 3px 0px'
				}}
			>
				{isPromoted && (
					<div className="absolute -top-[14px] right-2 z-10 w-fit rounded-lg border border-gray-300 bg-white px-2 py-1 text-sm font-bold text-gray-600">
						Trending &#x1f525;
					</div>
				)}

				<div className="md:h-70 relative !h-48 ">
					<Image
						src={image}
						alt={title}
						fill
						priority
						className="object-contain"
						sizes="(max-width: 768px) 100vw,
                        (max-width: 1200px) 50vw,
                        33vw"
						aria-hidden="true"
					/>
				</div>
				<div className="flex flex-1 flex-col justify-between gap-1.5 p-2">
					<div className="line-clamp-2 text-sm font-medium text-gray-700"> {title}</div>
					{location && <div className="text-sm font-medium text-gray-500"> {location} </div>}
					{duration && <div className="text-sm text-gray-500">{duration}</div>}
					{(label || registrationCount) && (
						<div className="mt-2 flex flex-row flex-wrap items-center justify-between gap-1">
							{label && <div>{label}</div>}
							<div className="text-xs font-semibold text-gray-700">{registrationCount} registrations</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

const FeaturedTournamentCard = ({ title, image, duration, location, tournamentId }: TournamentHomeCardProps) => {
	return (
		<ImpressionWrapperPages moduleId={tournamentId} moduleFor={SystemModuleType.FEATURED_TOURNAMENT}>
			<div className="flex h-full flex-row flex-wrap gap-2 rounded-lg border bg-white p-2 md:flex-nowrap">
				<div className="relative m-auto h-40 w-full !rounded-lg md:h-20 md:w-1/3">
					<Image
						src={image}
						alt={title}
						fill
						priority
						className="rounded-lg object-contain"
						sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
					/>
				</div>
				<div className="flex flex-col justify-between md:w-2/3">
					<div>
						<div className="text-sm text-gray-500">{duration}</div>
						<div className="line-clamp-2 text-sm font-medium text-gray-700">{title}</div>
					</div>
					{location && <div className="text-sm font-medium text-gray-500"> {location} </div>}
				</div>
			</div>
		</ImpressionWrapperPages>
	);
};

interface SliderSectionProps {
	title: string;
	subtitle?: string;
	customClassName?: string;
	data: TournamentHomeCardProps[];
	showAllRedirectURL?: string;
	redirectToNewDetailsPage?: boolean;
	renderCardAd?: boolean;
	ipAddress?: string;
}

const SliderSection = ({
	title,
	customClassName,
	data,
	showAllRedirectURL,
	subtitle,
	redirectToNewDetailsPage,
	renderCardAd,
	ipAddress
}: SliderSectionProps) => {
	const containerRef = React.useRef<HTMLDivElement>(null);
	const interval = useRef<NodeJS.Timer | null>(null);

	const prevScroll = () => {
		if (containerRef.current) {
			containerRef.current.scroll({
				left: containerRef.current.scrollLeft - 300,
				behavior: 'smooth'
			});
		}
	};

	const nextScroll = () => {
		if (containerRef.current) {
			containerRef.current.scroll({
				left: containerRef.current.scrollLeft + 300,
				behavior: 'smooth'
			});
		}
	};

	const prevScrollHold = () => {
		interval.current = setInterval(() => {
			prevScroll();
		}, 500);
	};

	const nextScrollHold = () => {
		interval.current = setInterval(() => {
			nextScroll();
		}, 500);
	};

	const handleMouseUp = () => {
		if (interval.current) {
			clearInterval(interval.current);
			interval.current = null;
		}
	};

	const [isFirstItemVisible, setIsFirstItemVisible] = useState(true);
	const [isLastItemVisible, setIsLastItemVisible] = useState(false);

	const handleScroll = () => {
		const container = containerRef.current;

		if (container) {
			const isAtStart = container.scrollLeft === 0;
			const isAtEnd = container.scrollLeft + container.offsetWidth >= container.scrollWidth;

			setIsFirstItemVisible(isAtStart);
			setIsLastItemVisible(isAtEnd);
		}
	};

	useEffect(() => {
		const container = containerRef.current;
		if (container) {
			container.addEventListener('scroll', handleScroll);
		}
		return () => {
			if (container) {
				container.removeEventListener('scroll', handleScroll);
			}

			if (interval.current) {
				clearInterval(interval.current);
			}
		};
	}, []);

	const handleCardClick = async (e: React.MouseEvent, tourneySlug: string) => {
		e.preventDefault();
		const url = `${process.env.NEXT_PUBLIC_PT_URL}${redirectToNewDetailsPage ? '/tournament/' : '/tournaments/'}${tourneySlug}`;
		setTimeout(() => {
			window.location.href = url;
		}, 100);
	};

	return (
		<div className={classNames('py-8', customClassName)}>
			<div className="container mx-auto flex max-w-screen-2xl flex-row items-baseline justify-between">
				<div className="text-2xl font-semibold text-gray-900">
					<h1 tabIndex={0}>{title}</h1>
					{subtitle && <div className="text-base font-medium text-gray-500">{subtitle}</div>}
				</div>

				{showAllRedirectURL && (
					<a href={showAllRedirectURL}>
						<Button className="!text-blue-500" variant="link">
							Show all
						</Button>
					</a>
				)}
			</div>
			<div className="container relative mx-auto max-w-screen-2xl">
				<div className="hidden-scrollbar -mb-4 mt-4 overflow-x-auto py-4" ref={containerRef}>
					{!isFirstItemVisible && (
						<button
							type="button"
							className="absolute left-1 top-[30%] z-10 flex size-10 cursor-pointer items-center justify-center rounded-full border border-gray-500 bg-white/70 backdrop-blur-sm transition duration-150 hover:bg-white disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-300 disabled:hover:bg-white lg:size-14 2xl:-left-4"
							onMouseDown={prevScrollHold}
							onTouchStart={prevScrollHold}
							onClick={prevScroll}
							onMouseUp={handleMouseUp}
							onMouseLeave={handleMouseUp}
							onTouchEnd={handleMouseUp}
							role="button"
							aria-label="Previous"
						>
							<FontAwesomeIcon icon={faArrowLeft} className="block text-base text-gray-500" />
						</button>
					)}

					{!isLastItemVisible && (
						<button
							type="button"
							className="absolute right-1 top-[30%] z-10 ml-4 flex size-10 cursor-pointer items-center justify-center rounded-full border border-gray-500 bg-white/70 backdrop-blur-sm transition duration-150 hover:bg-white disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-300 disabled:hover:bg-white lg:size-14 2xl:-right-4 "
							onMouseDown={nextScrollHold}
							onTouchStart={nextScrollHold}
							onClick={nextScroll}
							onMouseUp={handleMouseUp}
							onMouseLeave={handleMouseUp}
							onTouchEnd={handleMouseUp}
							role="button"
							aria-label="Next"
						>
							<FontAwesomeIcon icon={faArrowRight} className="block text-base text-gray-500" />
						</button>
					)}

					<div className="inline-block">
						<div className="flex gap-4 md:gap-8">
							{renderCardAd && (
								<div className="inline-grid max-h-full min-h-full w-[245px]">
									<TournamentsCustomAd
										zoneId="788107"
										pageId={100003}
										place={1}
										width={0}
										height={0}
										className="relative"
										ipAddress={ipAddress}
									/>
								</div>
							)}
							{data &&
								data.length > 0 &&
								data.map((tournament, index) => (
									<Link
										key={index}
										onClick={(e) => handleCardClick(e, tournament.slug || tournament.tournamentId)}
										href={`${process.env.NEXT_PUBLIC_PT_URL}${redirectToNewDetailsPage ? '/tournament/' : '/tournaments/'}${tournament.slug || tournament.tournamentId}`}
									>
										<div className="inline-grid h-full w-[245px]">
											{tournament.isRecommendedTournament ? (
												<ImpressionWrapperPages
													moduleId={tournament.tournamentId}
													moduleFor={SystemModuleType.RECOMMENDED_TOURNAMENT}
												>
													<TournamentHomeCard
														location={tournament.location}
														duration={tournament.duration}
														title={tournament.title}
														image={tournament.image}
														label={tournament.label}
														registrationCount={tournament.registrationCount}
														isPromoted={tournament.isPromoted}
														tournamentId={tournament.tournamentId}
													/>
												</ImpressionWrapperPages>
											) : (
												<TournamentHomeCard
													location={tournament.location}
													duration={tournament.duration}
													title={tournament.title}
													image={tournament.image}
													label={tournament.label}
													registrationCount={tournament.registrationCount}
													isPromoted={tournament.isPromoted}
													tournamentId={tournament.tournamentId}
												/>
											)}
										</div>
									</Link>
								))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
