export enum HomePageMarketingType {
	HPM_UNDEFINED_COLUMN = 0,
	HPM_TOURS_SERIES_COLUMN = 1,
	HPM_ASSOCIATIONS_COLUMN = 2,
	HPM_PARTNERS_COLUMN = 3,
	UNRECOGNIZED = -1
}

export enum SystemModuleType {
	UNDEFINED_SYSTEM_MODULE_TYPE = 0,
	HPM_TOURS_SERIES_COLUMN = 1,
	HPM_ASSOCIATIONS_COLUMN = 2,
	HPM_PARTNERS_COLUMN = 3,
	TOURNAMENT_SYSTEM_MODULE_TYPE = 4,
	ORGANIZATION_SYSTEM_MODULE_TYPE = 11,
	LEAGUE_SYSTEM_MODULE_TYPE = 12,
	GLOBAL_SYSTEM_MODULE_TYPE = 109,
	TEAM_LEAGUES_SYSTEM_MODULE_TYPE = 120,
	TEAM_LEAGUE_SEASONS_MODULE_TYPE = 127,
	TEAM_LEAGUE_DISTRICT_SYSTEM_MODULE_TYPE = 136,
	TEAM_LEAGUE_TEAMS_SYSTEM_MODULE_TYPE = 138,
	TEAM_LEAGUE_DIVISIONS_SYSTEM_MODULE_TYPE = 141,
	CLUB_V1_SYSTEM_MODULE_TYPE = 148,
	RECOMMENDED_TOURNAMENT = 158,
	FEATURED_TOURNAMENT = 159,
	UNRECOGNIZED = -1
}
