import { RouteResponse } from '@pickleballinc/lib/server/next/route/types';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { GetCreatePermissionsRes } from '@/pages/api/user/getCreatePermissions';

export const useGetCreatePermissions = (moduleFor: string, isLoggedIn: boolean) => {
	return useQuery(['createPermissions', moduleFor], async () => getCreatePermissions(moduleFor), {
		refetchOnMount: false,
		enabled: isLoggedIn
	});
};

const getCreatePermissions = async (moduleFor: string) => {
	const params = new URLSearchParams({
		moduleFor
	});

	const response = await axios.get<RouteResponse<GetCreatePermissionsRes>>('/api/user/getCreatePermissions', {
		params
	});

	return response.data.data;
};
